<style scoped>
  body{
    min-height: 100%;
  }
  .tab-list{
    border-bottom: 1px solid #eeeeee;
  }
  .tab-list ul{
    display: -webkit-box;
  }
  .tab-list ul li{
    -webkit-box-flex: 1;
    text-align: center;
    font-size: 0.875rem;
    line-height: 40px;
    height: 40px;
  }
  .tab-list ul li div{
    display: inline-block;
    width: 60px;
  }
  .tab-list ul li span{
    position: relative;
    top: -1px;
    border-bottom: 2px solid transparent;
  }
  .tab-list ul li.on div span{
    display: block;
    color: #656fef;
    border-bottom: 2px solid #656fef;
  }
  .tab-con{
    min-height: calc(100% - 41px);
    overflow: hidden;
  }
  .chapter-bgm .near-used{
    padding: 0px 0px 15px 15px;
  }
  .type-con{
    overflow: hidden;
    height: 100%;
  }
  .type-con .second-list{
    float: left;
    background: rgba(255,255,255,0.5);
    height: 100%;
  }
  .type-con .second-list li{
    height: 35px;
    line-height: 35px;
    width: 80px;
    text-align: center;
    border-bottom: 1px solid #e5e5e5;
    color: #777777;
  }
  .type-con .second-list li.on{
    background: #547CFF;
    color: #fff;
    border-bottom: 1px solid #547CFF;
  }
  .type-con .bgm-list{
    margin-left: 80px;
    padding: 0px 10px 10px;
  }
  .bgm-list-con li{
    padding: 15px 0;
    line-height: 22px;
    border-bottom: 1px solid #eeeeee;
    overflow: hidden;
    color: #333;
  }
  .bgm-list-con li div{
    position: relative;
  }
  .bgm-list-con li label{
    display: block;
    float: left;
    padding-right: 40px;
  }
  .bgm-list-con li p{
    margin-top: 5px;
    padding-left: 18px;
    color: #848484;
    font-size: 0.8rem;
    background: url("../../assets/image/icon_time01.png") left center no-repeat;
    background-size: auto 15px;
    line-height: 15px;
  }
  .bgm-list-con li .icon-add{
    position: absolute;
    top: 10px;
    right: 15px;
    width: 18px;
    height: 18px;
    background: url("../../assets/image/icon_add01.png") no-repeat;
    background-size: 100% 100%;
  }
  .bgm-list-con li.on{
    color: #656fef;
  }
  .bgm-list-con li div.isPlay{
    color: #656fef;
  }
  .bgm-list-con li div img{
    vertical-align: middle;
    position: relative;
    top: -1px;
    margin-left: 10px;
  }
  .bgm-list-con li div.isPlay .icon-add{
    background: url("../../assets/image/icon_add02.png") no-repeat;
    background-size: 100% 100%;
  }
  .bgm-list-con li.on div .icon-add{
    background: url("../../assets/image/icon_slt01.png") no-repeat;
    background-size: 100% 100%;
  }
  .bgm-list-con li div.isPlay p, .bgm-list-con li.on div p{
    color: #9da3ed;
    background: url("../../assets/image/icon_time02.png") left center no-repeat;
    background-size: auto 15px;
  }
  .booknull{
    margin-top: 180px;
  }
  .pagebgimg{
      position: fixed;
      top: 0;
      z-index: -1;
      width: 100%;height: 100%;
    }
    .pagebgimg>img{
      width: 100%;height: 100%;
    }
</style>
<template>
  <div class="chapter-bgm">
    <header-title titleCon="音乐库" titleBtnStr="完成" @titleLeftBtn="titleFnReturn" @titleFnBtn="titleFnListener"></header-title>
    <div class="chpater-bgm-list" id="chapterBgCon">
      <div class="tab-list">
        <ul>
          <li :class="typeActive.firstTypeIndex === 0 ? 'on': ''">
            <div><span @click="firstNearFn">最近使用</span></div>
          </li>
          <li v-for="(item, index) in ugcMusicTypes" :key="index" :class="typeActive.firstTypeIndex === (index + 1) ? 'on': ''">
            <div><span @click="firstTypeFn(item.musicType, index+1)">{{item.musicTypeName}}</span></div>
          </li>
        </ul>
      </div>
      <div class="tab-con">
        <!--最近使用-->
        <div class="near-used" v-if="typeActive.firstTypeId === 11">
          <ul class="bgm-list-con" v-if="nearList.length>0">
            <li v-for="(item, index) in nearList" :key="index" :class="item.selected ? 'on': ''">
              <div :class="((playIndex === index) && isPlay) ? 'isPlay' : ''">
                <label @click="changeStart('music' + item.id, index)">
                  <span>{{item.musicTitle}}<img v-if="(playIndex === index) && isPlay" src="../../assets/image/icon_play.png" width="18"></span>
                  <p>{{item.musicTimeStr}}</p>
                  <audio :src="item.musicPath" :id="'music' + item.id" @οnended="video"></audio>
                </label>
                <i class="icon-add" @click="changeBgm(index)"></i>
              </div>
            </li>
          </ul>
          <div v-else>
            <div class="booknull">
              <div class="booknullimg">
                <img src="../../assets/image/book_null.png">
                <p>暂无使用历史~</p>
              </div>
            </div>
          </div>
        </div>
        <!--二级分类-->
        <div class="type-con" v-else>
          <div class="second-list">
            <ul>
              <li v-for="(item, index) in musicSubType" :key="index" :data-id="item.id" @click="subTypeShow(item.id, index)" :class="typeActive.subTypeIndex === index ? 'on': ''">
                {{item.subTypeName}}
              </li>
            </ul>
          </div>
          <div class="bgm-list">
            <ul class="bgm-list-con" v-if="ugcMusicList.length>0">
              <li v-for="(item, index) in ugcMusicList" :key="index" :class="item.selected ? 'on': ''">
                <div :class="((playIndex === index) && isPlay) ? 'isPlay' : ''">
                  <label @click="changeStart('music' + item.id, index)">
                    <span>{{item.musicTitle}}<img v-if="(playIndex === index) && isPlay" src="../../assets/image/icon_play.png" width="18"></span>
                    <p>{{item.musicTimeStr}}</p>
                    <audio :src="item.musicPath" :id="'music' + item.id" @οnended="video"></audio>
                  </label>
                  <i class="icon-add" @click="changeBgm(index)"></i>
                </div>
              </li>
            </ul>
            <div v-else>
              <div class="booknull">
                <div class="booknullimg">
                  <img src="../../assets/image/book_null.png">
                  <p>暂无该分类~</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="pagebgimg">
          <img :src="pagebg" alt="">
    </div>
  </div>
</template>
<script>
import headerTitle from '../../components/header/header-title-transparent.vue'
export default {
  components: {
    headerTitle
  },
  data () {
    return {
      windowHeight: document.documentElement.clientHeight,
      pagebg: require('../../assets/image/pagebgsm.png'),
      isPlay: false,
      isStore: true,
      playIndex: -1,
      isSelect: false,
      playtime: '1:41',
      sltBgmId: -1,
      typeActive: {
        firstTypeIndex: 0, // 一级分类选中
        firstTypeId: 11, // 一级分类选中的id
        subTypeIndex: 0, // 二级分类选中
        subTypeId: 0,
        listActive: -1 // 背景选中
      },
      sltCptBgmActive: {
        firstTypeIndex: 0, // 一级分类选中
        firstTypeId: 11, // 一级分类选中的id
        subTypeIndex: 0, // 二级分类选中
        subTypeId: 0,
        listActive: -1 // 背景选中
      },
      ugcMusicList: [], // 背景列表
      ugcMusicTypes: [], // 一级分类所有列表
      musicSubType: [], // 选中的一级分类下的二级分类列表
      nearList: []
    }
  },
  computed: {
  },
  created () {
    // 适配背景图片
    if (this.windowHeight > 736) {
      this.pagebg = require('../../assets/image/pagebgbig.png')
    }
    // 分类接口
    this.axios({
      method: 'get',
      url: '/v1/musicList',
      params: {
        musicType: '',
        musicSubType: ''
      }
    }).then((res) => {
      if (res.data.head.flag === 0) {
        // console.log(res.data.content)
        this.ugcMusicTypes = res.data.content.ugcMusicTypes || []
      } else {
        this.$toast(res.data.head.desc)
      }
    })
    // 最近使用
    this.axios({
      method: 'get',
      url: '/v1/recentMusics',
      params: {
        // authorId: sessionStorage.getItem('authorId')
      }
    }).then((res) => {
      if (res.data.head.flag === 0) {
        this.nearList = res.data.content.ugcMusicList || []
      } else {
        this.$toast(res.data.head.desc)
      }
    })
  },
  mounted () {
    document.getElementById('chapterBgCon').style.height = (window.screen.height - 46) + 'px'
    // 默认选中
    if ((localStorage.getItem('sltCptBgmActive') !== 'null') && (localStorage.getItem('sltCptBgmActive') !== null) && (localStorage.getItem('sltCptBgmActive') !== undefined) && (localStorage.getItem('sltCptBgmActive') !== 'undefined') && (localStorage.getItem('sltCptBgmActive') !== '')) {
      this.typeActive = JSON.parse(localStorage.getItem('sltCptBgmActive'))
      this.sltBgmId = parseInt(localStorage.getItem('sltBgmId'))
      if (this.typeActive.firstTypeIndex !== 0) {
        this.axios({
          method: 'get',
          url: '/v1/musicList',
          params: {
            musicType: this.typeActive.firstTypeId,
            musicSubType: this.typeActive.subTypeId
          }
        }).then((res) => {
          if (res.data.head.flag === 0) {
            this.musicSubType = res.data.content.ugcMusicTypes[this.typeActive.firstTypeIndex - 1].musicSubType
            this.ugcMusicList = res.data.content.ugcMusicList
            this.ugcMusicList[this.typeActive.listActive].selected = true
          } else {
            this.$toast(res.data.head.desc)
          }
        })
      } else {
        this.axios({
          method: 'get',
          url: '/v1/recentMusics',
          params: {
            // authorId: sessionStorage.getItem('authorId')
          }
        }).then((res) => {
          if (res.data.head.flag === 0) {
            this.nearList = res.data.content.ugcMusicList || []
            this.nearList.forEach(function (event) {
              if (event.id === parseInt(localStorage.getItem('sltBgmId'))) { // 最近使用选中
                // console.log(event.id)
                event.selected = true
              }
            })
          } else {
            this.$toast(res.data.head.desc)
          }
        })
      }
    }
  },
  methods: {
    // 选择背景返回
    titleFnReturn () {
      this.$router.go(-1)
    },
    titleFnListener () {
      this.sltCptBgmActive = this.typeActive
      if (this.nearList.length > 0 || this.ugcMusicList.length > 0) { // 列表有数据
        if (this.sltCptBgmActive.listActive !== -1) { // 选中背景
          this.$store.state.sltCptBgmActive = this.sltCptBgmActive
          localStorage.setItem('sltCptBgmActive', JSON.stringify(this.sltCptBgmActive))
          localStorage.setItem('sltBgmId', this.sltBgmId)
          if (this.sltCptBgmActive.firstTypeIndex === 0) { // 最近使用列表
            this.$store.state.chapterInfo.chapterBgm = this.nearList[this.sltCptBgmActive.listActive]
          } else { // 二级分类列表
            this.$store.state.chapterInfo.chapterBgm = this.ugcMusicList[this.sltCptBgmActive.listActive]
          }
        } else { // 取消背景
          this.$store.state.chapterInfo.chapterBgm = {
            addTime: '',
            musicPath: '',
            musicSubType: -1,
            musicTitle: '',
            musicType: -1,
            id: '',
            rackStatus: -1,
            selected: false,
            updTime: null
          }
          localStorage.setItem('sltCptBgmActive', '')
          this.$store.state.sltCptBgmActive = {
            firstTypeIndex: '',
            firstTypeId: '',
            subTypeIndex: '',
            subTypeId: '',
            listActive: ''
          }
        }
        history.go(-1)
        // this.$router.push({ path: '/chapterAdd', query: { bookId: this.$route.query.bookId, chapterId: this.$route.query.chapterId, chapterType: this.$route.query.chapterType, type: this.$route.query.type } })
      } else {
        this.$toast('没有该分类')
      }
    },
    // 最近使用切换
    firstNearFn () {
      this.typeActive.firstTypeIndex = 0
      this.typeActive.firstTypeId = 11
      var that = this
      this.nearList.forEach(function (event) {
        if (event.id === that.sltBgmId) { // 最近使用选中
          event.selected = true
        }
      })
    },
    // 一级分类切换
    firstTypeFn (firstTypeId, firstTypeIndex) {
      this.typeActive.firstTypeIndex = firstTypeIndex
      this.typeActive.firstTypeId = firstTypeId
      this.typeActive.subTypeIndex = 0
      this.typeActive.subTypeId = this.ugcMusicTypes[firstTypeIndex - 1].musicSubType[this.typeActive.subTypeIndex].id
      var that = this
      this.axios({
        method: 'get',
        url: '/v1/musicList',
        params: {
          musicType: firstTypeId,
          musicSubType: this.ugcMusicTypes[firstTypeIndex - 1].musicSubType[0].id
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          that.musicSubType = res.data.content.ugcMusicTypes[firstTypeIndex - 1].musicSubType
          that.ugcMusicList = res.data.content.ugcMusicList
          that.ugcMusicList.forEach(function (event) {
            if (event.id === that.sltBgmId) { // 最近使用选中
              event.selected = true
            }
          })
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 二级分类切换
    subTypeShow (subTypeId, subTypeIndex) {
      this.typeActive.subTypeIndex = subTypeIndex
      this.typeActive.subTypeId = subTypeId
      var that = this
      this.axios({
        method: 'get',
        url: '/v1/musicList',
        params: {
          musicType: this.typeActive.firstTypeId,
          musicSubType: subTypeId
        }
      }).then((res) => {
        if (res.data.head.flag === 0) {
          this.ugcMusicList = res.data.content.ugcMusicList
          that.ugcMusicList.forEach(function (event) {
            if (event.id === that.sltBgmId) { // 最近使用选中
              event.selected = true
            }
          })
        } else {
          this.$toast(res.data.head.desc)
        }
      })
    },
    // 音乐播放
    changeStart (id, index) {
      this.playIndex = index
      this.isStore = !this.isStore
      const musicMp3 = document.getElementById(id)
      // console.log(musicMp3.duration)
      // console.log(musicMp3.paused)
      if (musicMp3.paused) {
        this.isPlay = true
        musicMp3.play()
      } else {
        this.isPlay = false
        musicMp3.pause()
      }
    },
    video () {
      // console.log('音乐')
    },
    changeBgm (index) {
      // this.sltCptBgmActive.listActive = index
      // this.isSelect = !this.isSelect
      if (this.typeActive.firstTypeIndex === 0) {
        if (this.nearList[index].selected) {
          this.nearList[index].selected = false
          this.typeActive.listActive = -1
        } else {
          this.nearList.forEach(function (e) {
            e.selected = false
          })
          this.typeActive.listActive = index
          this.nearList[index].selected = true
          this.sltBgmId = this.nearList[index].id
        }
      } else {
        if (this.ugcMusicList[index].selected) {
          this.ugcMusicList[index].selected = false
          this.typeActive.listActive = -1
        } else {
          this.ugcMusicList.forEach(function (e) {
            e.selected = false
          })
          this.typeActive.listActive = index
          this.ugcMusicList[index].selected = true
          this.sltBgmId = this.ugcMusicList[index].id
        }
      }
    }
  }
}
</script>
